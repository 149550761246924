import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyD96_-7YoC3JngyqPJwYCdvoN3b8dBQrXA",
  authDomain: "projectundo-eeb46.firebaseapp.com",
  projectId: "projectundo-eeb46",
  storageBucket: "projectundo-eeb46.appspot.com",
  messagingSenderId: "378761076889",
  appId: "1:378761076889:web:1066f371fe103712f65808"
};

export const app = initializeApp(firebaseConfig);
export const dataBase = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);