import React, { useState, useEffect } from 'react';
import '../css/style.css';
import { Nav, Form, FormControl, Button, Navbar, Container, NavDropdown, navbarScroll } from "react-bootstrap-v5";
import '../css/style.css';

const Header = (props) => {
    const loggedIn = localStorage.getItem('loggedin');
    // const scrollFunction = function () {
    //     // scrollFunction()
    //     window.onscroll();
    // }
    // const scrollToBottom = (id) => {
    //     if (document.body.scrollTop > 5 || document.documentElement.scrollTop > 5) {
    //         document.getElementById("scrollNav").style.position = 'FIXED';
    //     } else {
    //         document.getElementById("scrollNav").style.backgroundColor = "blue";
    //       }
    // }
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);
    console.log(offset); 
    // const [loggedIn, setLoggedIn] = useState(false);
    return(
        <>
        
      <div>hvhv</div>
        </>
        
    )
}
export default Header;