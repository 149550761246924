import React, { useState } from 'react';
// import Header from '../../Header/Header';
// import Footer from '../../Header/footer';
// import { fetchCourses } from "../../../crud/common.crud";
// import { fetchStation,fetchMenu,fetchRestaurant,fetchPnr ,fetchBlog} from "../../../crud/common.crud";
import logo from '../../images/subesh/ProjectUndoLogoWhite.webp';
import dbg1 from '../../images/subesh/Home1.webp';
import dbg2 from '../../images/subesh/Home2.webp';
import dbg3 from '../../images/subesh/Home3.webp';
import qr from '../../images/Wechat QR.webp';
import { Modal,Carousel } from 'react-bootstrap-v5';
import '../../css/style.css';

import "react-multi-carousel/lib/styles.css";

// import { Typewriter, useTypewriter, Cursor } from 'react-simple-typewriter';

const Home = (props) => {


const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
    return (

        <>
         <a href="/"><img src={logo} className="img-responsive imgOverlay" width="200px" alt='project undo logo'/></a>
        <Carousel  controls={false}>
          <Carousel.Item className='carousel-item1'>
            <img
              className="d-block w-100"
              src={dbg1}
              alt="First slide"
            />
            <Carousel.Caption className='carousel-captions'>
              <h3 className='sliderH3' style={{fontFamily:'none'}}>
                <span className='fs-6 px-2 py-1 rounded-pill bg-white text-dark border-0 '>
                  <a href='/admin/thoughtposts' className='text-decoration-none text-dark tablet-text'>Thought Post</a>
                </span>
                <span className='fs-6 px-2 py-1 rounded-pill bg-white border-0 ms-2'>
                  <a href='/profile.html' className='text-decoration-none text-dark tablet-text' target="_blank">View Profile</a>
                </span>
              </h3>
              <p className='sliderP'>
                <a href='mailto:subesh.poddar23@hotmail.com'>
                <span className='text-dark rounded-circle bg-white'>
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                </span>
                </a>
                {/* <a href='https://www.linkedin.com/in/subeshpoddar23/' target="_blank">
                <span className='text-dark rounded-circle bg-white ms-1'>
                  <i className="fa fa-linkedin" aria-hidden="true"></i>
                </span>
                </a> */}
                {/* <a href='https://www.instagram.com/aeonsubesh/' target="_blank">
                <span className='text-dark rounded-circle bg-white ms-1'>
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </span>
                </a> */}
                {/* <a href='#1' onClick={handleShow}>
                <span className='text-dark rounded-circle bg-white ms-1'>
                  <i className="fa fa-weixin" aria-hidden="true"></i>
                </span>
                </a> */}
                {/* <a href='mailto:'>
                <span className='text-dark rounded-circle bg-white ms-1'>
                  <i className="fa fa-twitter" aria-hidden="true"></i>
                </span>
                </a>              
                <a href='mailto:'>  
                <span className='text-dark rounded-circle bg-white ms-1'>
                  <i className="fa fa-youtube-play" aria-hidden="true"></i>
                </span>
                </a> */}
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className='carousel-item2'>
            <img
              className="d-block w-100"
              src={dbg2}
              alt="Second slide"
            />

            <Carousel.Caption className='carousel-captions'>
            <h3 className='sliderH3' style={{fontFamily:'none'}}>
                <span className='fs-6 px-2 py-1 rounded-pill bg-white text-dark border-0 '>
                  <a href='/admin/thoughtposts' className='text-decoration-none text-dark tablet-text'>Thought Post</a>
                </span>
                <span className='fs-6 px-2 py-1 rounded-pill bg-white border-0 ms-2'>
                  <a href='/profile.html' className='text-decoration-none text-dark tablet-text' target="_blank">View Profile</a>
                </span>
              </h3>
              <p className='sliderP'>
                <a href='mailto:subesh.poddar23@hotmail.com'>
                <span className='text-dark rounded-circle bg-white'>
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                </span>
                </a>
                {/* <a href='https://www.linkedin.com/in/subeshpoddar23/' target="_blank">
                <span className='text-dark rounded-circle bg-white ms-1'>
                  <i className="fa fa-linkedin" aria-hidden="true"></i>
                </span>
                </a> */}
                {/* <a href='https://www.instagram.com/aeonsubesh/' target="_blank">
                <span className='text-dark rounded-circle bg-white ms-1'>
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </span>
                </a> */}
                {/* <a href='#1' onClick={handleShow}>
                <span className='text-dark rounded-circle bg-white ms-1'>
                  <i className="fa fa-weixin" aria-hidden="true"></i>
                </span>
                </a> */}
                {/* <a href='mailto:'>
                <span className='text-dark rounded-circle bg-white ms-1'>
                  <i className="fa fa-twitter"></i>
                </span>
                </a>              
                <a href='mailto:'>  
                <span className='text-dark rounded-circle bg-white ms-1'>
                  <i className="fa fa-youtube-play" aria-hidden="true"></i>
                </span>
                </a> */}
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className='carousel-item1'>
            <img
              className="d-block w-100"
              src={dbg3}
              alt="Third slide"
            />

            <Carousel.Caption className='carousel-captions'>
            <h3 className='sliderH3' style={{fontFamily:'none'}}>
                <span className='fs-6 px-2 py-1 rounded-pill bg-white text-dark border-0 '>
                  {/* <a className='text-decoration-none text-dark tablet-text'>Thought Post</a> */}
                  <a href='/admin/thoughtposts' className='text-decoration-none text-dark tablet-text'>Thought Post</a>
                </span>
                <span className='fs-6 px-2 py-1 rounded-pill bg-white border-0 ms-2'>
                  <a href='/profile.html' className='text-decoration-none text-dark tablet-text' target="_blank">View Profile</a>
                </span>
              </h3>
              <p className='sliderP'>
                <a href='mailto:subesh.poddar23@hotmail.com'>
                <span className='text-dark rounded-circle bg-white'>
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                </span>
                </a>
                {/* <a href='https://www.linkedin.com/in/subeshpoddar23/' target="_blank">
                <span className='text-dark rounded-circle bg-white ms-1'>
                  <i className="fa fa-linkedin" aria-hidden="true"></i>
                </span>
                </a> */}
                {/* <a href='https://www.instagram.com/aeonsubesh/' target="_blank">
                <span className='text-dark rounded-circle bg-white ms-1'>
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </span>
                </a> */}
                {/* <a href='#1' onClick={handleShow}>
                <span className='text-dark rounded-circle bg-white ms-1'>
                  <i className="fa fa-weixin" aria-hidden="true"></i>
                </span>
                </a> */}
                {/* <a href='mailto:'>
                <span className='text-dark rounded-circle bg-white ms-1'>
                  <i className="fa fa-twitter"></i>
                </span>
                </a>              
                <a href='mailto:'>  
                <span className='text-dark rounded-circle bg-white ms-1'>
                  <i className="fa fa-youtube-play" aria-hidden="true"></i>
                </span>
                </a> */}
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

        <Modal show={show} onHide={handleClose}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        
          <img src={qr} alt='Subesh Wechat'/>
        
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
    )
}
export default Home;