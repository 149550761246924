import React from 'react';
import './firebaseConfig';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Pages/Home/Home';
// import Post from './components/Pages/post/post';
import Contact from './components/Pages/Contact/Contact';
// import SinglePost from './components/Pages/SinglePost/SinglePost';
// import Dashboard from './components/Pages/BackendPanel/Dashboard';
// import Login from './components/Pages/BackendPanel/Login';
// import Register from './components/Pages/BackendPanel/Register';
// import Blogs from './components/Pages/BackendPanel/Blogs';
// import AddBlogs from './components/Pages/BackendPanel/AddBlogs';
// import EditBlogs from './components/Pages/BackendPanel/EditBlogs';

function App() {
 
  return (
    <div className="App">      
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />}/>
          {/* <Route exact path="/thoughtPost" element={<Post />}/> */}
          {/* <Route exact path="/singlepost/:id" element={<SinglePost />}/> */}
          {/* <Route exact path="/dashboard" element={<Dashboard />}/> */}
          {/* <Route exact path="/login" element={<Login />}/> */}
          {/* <Route exact path="/blogs" element={<Blogs />}/> */}
          {/* <Route exact path="/addblog" element={<AddBlogs />}/> */}
          {/* <Route path="/editblogs/:id" element={<EditBlogs />} /> */}
          {/* <Route exact path="/Register" element={<Register />}/> */}
          <Route exact path="/contact" element={<Contact />}/>
        </Routes>
      </Router>
    </div>
  )
}
export default App;
